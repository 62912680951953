/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import {
  WhatsappShareButton,
  WhatsappIcon,
  InstapaperShareButton,
  InstapaperIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import './SharePost.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { urls } from '../../helpers/common';

function SharePost({ post }) {
  console.log({ post });
  const { BACK_END } = process.env;
  const base_url = BACK_END && urls[BACK_END] ? urls[BACK_END] : urls.dev;

  const post_url = `${base_url}/user/profile?post=${post?.id}&profile=${post?.profile_id}`;
  console.log({ urls, post, base_url, post_url, BACK_END });

  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(post_url);
      setCopied(true);
      setTimeout(() => setCopied(false), 600);
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
  };

  const handleMail = () => {
    const emailSubject = 'Check out this post!';
    const emailBody = `I found this interesting post: ${post_url}`;

    const mailtoLink = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;

    window.location.href = mailtoLink;
  };

  return (
    <div className="share-holder">
      <div className="share-btn">
        <FacebookShareButton url={post_url}>
          <FacebookIcon round size={35} />
        </FacebookShareButton>
      </div>
      <div className="share-btn">
        <InstapaperShareButton url={post_url}>
          <InstapaperIcon round size={35} />
        </InstapaperShareButton>
      </div>
      <div className="share-btn">
        <LinkedinShareButton url={post_url}>
          <LinkedinIcon round size={35} />
        </LinkedinShareButton>
      </div>
      <div className="share-btn">
        <TwitterShareButton url={post_url}>
          <TwitterIcon round size={35} />
        </TwitterShareButton>
      </div>
      <div className="share-btn">
        <WhatsappShareButton url={post_url}>
          <WhatsappIcon round size={35} />
        </WhatsappShareButton>
      </div>
      <div className="share-btn">
        <div className="copy-btn" onClick={handleCopy}>
          <span className="copy">copy url</span>
          <FontAwesomeIcon icon={faCopy} />
          {copied ? <span className="copied-text">Url Copied!</span> : null}
        </div>
      </div>
      <div className="share-btn">
        <div className="copy-btn" onClick={handleMail}>
          <span className="copy">share to mail</span>

          <FontAwesomeIcon icon={faEnvelope} />
        </div>
      </div>
    </div>
  );
}

export default SharePost;
