/* eslint-disable react/jsx-filename-extension */
import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import BannerVideo from '../components/BannerVideo';
import SliderBlock from '../components/SliderBlock';
import TwoColumns from '../components/TwoColumns';
import '../styles/common.scss';
import Button from '../components/Button';
import UserContext from '../context/userContext';
import { getCookie } from '../helpers/common';
import userService from '../services/userService';
// import { navigate } from 'gatsby';

function IndexPage() {
    const { setUser, user } = useContext(UserContext);

    useEffect(() => {
        const profile_id = getCookie('current_profile');
        if (!profile_id) {
            navigate('/user/login');
        }
        if (profile_id && !user) {
            console.log('in if ');
            userService
                .getCurrentProfile(profile_id)
                .then(async data => {
                    console.log({ data });
                    setUser({ ...data?.data, media: data?.data?.media });
                    // setLoading(false);
                })
                .catch(err => {
                    console.log({ err });
                });
        }
    }, []);

    return (
        <>
            <BannerVideo />
            <TwoColumns image={<StaticImage src="../images/img01.jpg" alt="img description" />} heading="Make a Legacy">
                <ul className="list">
                    <li>Make it easy to remember a life well lived.</li>
                    <li>Enable friends and family to contribute memories.</li>
                    <li>Get QR Codes to their memorial.</li>
                </ul>
                <Button
                    variant="primary"
                    onClick={() => {
                        navigate('/user/login');
                    }}>
                    Sign In
                </Button>
            </TwoColumns>
            <TwoColumns reverse image={<StaticImage src="../images/img02.jpg" alt="img description" />} heading="Features ">
                <ul className="list">
                    <li>Have a profile in memorial</li>
                    <li>Guests can leave comments and upload photos</li>
                    <li>QR Codes to link to this profile with iTombs</li>
                </ul>
            </TwoColumns>
            <TwoColumns image={<StaticImage src="../images/img03.jpg" alt="img description" />} heading="See it in Action">
                <p>
                    Have a memorial for a beautiful life with with contributions from all the people that love them all over the
                    world. Learn more by watching this video.
                </p>
            </TwoColumns>
            <SliderBlock />
        </>
    );
}

export default IndexPage;

export function Head() {
    return <title>Home Page</title>;
}
