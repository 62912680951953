import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { navigate } from '@gatsbyjs/reach-router';
import ProfileCover from '../../components/ProfileCover';
import EditProfileForm from '../../components/EditProfileForm';
import userService from '../../services/userService';
import { setCookie, getCookie } from '../../helpers/common';
import Toast from '../../components/Toast';
import UserContext from '../../context/userContext';

function EditProfile({ create }) {
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    name: '',
    date: null,
    phone_number: '',
    address: '',
    about_me: '',
    profile_photo: '',
    profile_cover: '',
    type: create ? 'create' : 'edit',
  });

  useEffect(() => {
    if (!create) {
      const val = {
        name: user?.name ?? '',
        date: user?.date ?? null,
        phone_number: user?.phone_number ?? '',
        address: user?.address ?? '',
        about_me: user?.about_me ?? '',
        profile_photo: user?.media?.profile_photo ?? '',
        profile_cover: user?.media?.profile_cover ?? '',
        type: create ? 'create' : 'edit',
        id: user?._id ?? null,
      };
      setValues(val);
    }
  }, []);

  const [assets, setAssets] = useState();
  const onChangeProfile = ({ name, value }) => {
    console.log({ value });
    if (!value) {
      setValues(prev => ({ ...prev, profile_photo: null }));
      return;
    }
    if (value?.type.startsWith('image/')) {
      // setErrors(prev => ({ ...prev, profile_photo: null }));
      setAssets(value);
      const file_obj = { file_name: value?.name, file_type: 'png' };
      // const files = Array.from(value).map(file => ({ name: file?.name, type: file?.type }));
      setValues(prev => ({ ...prev, profile_cover: file_obj, profile_photo: file_obj }));
    } else {
      Toast({ type: 'error', message: 'Only images are allowed' });
      // setErrors(prev => ({ ...prev, profile_photo: 'Only images are allowed' }));
    }
  };
  const handleSubmit = async () => {
    if (!values?.name) {
      Toast({ type: 'error', message: 'name is required' });
      return;
    }
    setLoading(true);
    // if (!values?.profile_photo) {
    //   Toast({
    //     type: 'error',
    //     message: 'Please upload an image',
    //   });
    //   setLoading(false);
    //   return;
    // }
    const valuesCopy = values;
    // delete values.profile_photo;
    // delete values.profile_cover;
    console.log({
      errors,
      values,
      cond1: Object.values(errors)?.some(error => error !== ''),
      cond2: !Object.values(valuesCopy).every(value => value !== ''),
      val: Object.values(valuesCopy).filter(key => key !== 'profile_photo' && key !== 'profile_cover'),
    });
    try {
      const res = await userService.createProfile(values);
      const profileObj = { ...res?.profile, ...res?.media };
      localStorage.setItem('itombs_profile', JSON.stringify(profileObj));
      if (res?.write_urls?.profile_photo) {
        await axios({
          method: 'put',
          url: res?.write_urls?.profile_photo, // write URL
          data: assets,
          headers: {
            'Content-Type': assets.type,
          },
        })
          .then()
          .catch(err => {});
      }
      setCookie('current_profile', res?.profile?._id);
      setUser(res?.profile);
      setLoading(false);
      navigate('/user/feed');
    } catch (err) {
      Toast({ type: 'error', message: 'Please login First' });
      setLoading(false);
    }
  };
  return (
    <>
      <ProfileCover
        create={create}
        hideFollow
        values={values}
        errors={errors}
        setErrors={setErrors}
        setValues={setValues}
        onChange={onChangeProfile}
        isEditMode={true}
      />
      <EditProfileForm
        create={create}
        values={values}
        setValues={setValues}
        onSubmit={handleSubmit}
        btnLoading={loading}
        errors={errors}
        setErrors={setErrors}
      />
    </>
  );
}

export default EditProfile;
