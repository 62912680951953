/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import { faQrcode, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QRCode from 'react-qr-code';
import profileCover from '../../images/profile_cover.png';
import './ProfileCover.scss';
import Button from '../Button';
import AvatarUploader from '../Avatar/AvatarUploader';
import Modal from '../Modal';
import userService from '../../services/userService';
import { getCookie, urls as baseUrls, _urls as backendUrls } from '../../helpers/common';
import UserContext from '../../context/userContext';
import Toast from '../Toast';

function ProfileCover({ values, showQr, create, onChange = () => {}, userData, hideFollow = false, isEditMode = false }) {
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const { user, hasPermission } = useContext(UserContext);
  const [followed, setIsFollowed] = useState(user?.followers_list?.includes(userData?._id) ?? false);

  const { BACK_END } = process.env;
  console.log(BACK_END);
  const _url = BACK_END && backendUrls[BACK_END] ? backendUrls[BACK_END] : backendUrls.dev;
  const base_url = BACK_END && baseUrls[BACK_END] ? baseUrls[BACK_END] : baseUrls.dev;

  const [qrValue, setQrValue] = useState(null);
  const profile_url = `${base_url}/user/profile?id=${userData?._id}`;
  useEffect(() => {
    if (userData) {
      const qr = `${_url}/qr/${userData?.qrdata?.qr}`;
      setQrValue(qr);
    }
  }, [userData,_url]);

  const handleChange = ({ name, value }) => {
    onChange({ name, value });
  };

  async function handleQrModalClick() {
    if (!isQRModalOpen) {
      const id = getCookie('current_profile');
      const response = await userService.getProfileQR({ profileId: id });
      setQrValue(qrValue);
    }
    setIsQRModalOpen(!isQRModalOpen);
  }

  const handleFollow = async type => {
    try {
      await userService.followOrUnfollow({
        profile_id: getCookie('current_profile'),
        type,
        following_profile_id: userData?._id,
      });
      if (type === 'follow') {
        setIsFollowed(true);
        Toast({ type: 'success', message: `You are following ${userData?.name}` });
      } else {
        setIsFollowed(false);
        Toast({ type: 'success', message: `You stopped following ${userData?.name}` });
      }
    } catch (error) {
      Toast({ type: 'error', message: error?.message ?? 'Something went wrong' });
    }
  };

  const handlShareProfile = async () => {
    try {
      await navigator.clipboard.writeText(profile_url);
      setCopied(true);
      setTimeout(() => setCopied(false), 600);
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
  };
  return (
    <div className="profile-cover" style={{ backgroundImage: `url(${profileCover})` }}>
      {!hideFollow && getCookie('current_profile') !== userData?._id && (
        <div className="pay-btn follow-btn">
          <Button
            variant="primary"
            onClick={() => {
              hasPermission(() => handleFollow(followed ? 'unfollow' : 'follow'));
            }}>
            {followed ? 'Unfollow' : 'Follow'}
          </Button>
        </div>
      )}
      <div className="avatar-holder my-avatar">
        {/* <Avatar create={create} /> */}

        {/* <AvatarUploader profile_photo={values?.profile_photo ?? user?.media?.profile_photo} onChange={handleChange} /> */}
        <AvatarUploader
          profile_photo={values?.profile_photo ?? userData?.media?.profile_photo}
          onChange={handleChange}
          isEditMode={isEditMode}
        />
      </div>
      <div className="text-holder">
        <div className="social-info">
          <div className="text-wrap">
            {userData?.name}
          </div>
        </div>
      </div>
      {!create && (
        <div className="text-holder">
          <div className="social-info">
            <div className="text-wrap">
              <span>Posts</span>
              <strong>{userData?.total_posts ?? 0}</strong>
            </div>
            <div className="text-wrap">
              <span>Likes</span>
              <strong>{userData?.likes ?? 0}</strong>
            </div>
            <div className="text-wrap">
              <span>Followers</span>
              <strong>{userData?.followers ?? 0}</strong>
            </div>
          </div>
          {userData?._id === getCookie('current_profile') && (
            <div
              className="edit-icon"
              onClick={() => {
                navigate('/user/edit-profile');
              }}>
              <StaticImage src="../../images/edit_icon.svg" alt="edit" />
            </div>
          )}
        </div>
      )}
      {/* {userData?._id === getCookie('current_profile') && showQr && ( */}
      <div className="qr-holder">
        {/* <div className="qr">
            <FontAwesomeIcon icon={faQrcode} onClick={handleQrModalClick} />
          </div> */}
        <div className="pay-btn">
          <Button
            variant="primary"
            onClick={() => {
              navigate('https://itombs.net/shop');
            }}>
            <span>
              <FontAwesomeIcon icon={faShoppingCart} />
            </span>
            Buy Now
          </Button>
        </div>
      </div>
      {/* )} */}
      <div className="share-holder">
        <div className="pay-btn">
          <Button
            variant="primary"
            onClick={() => {
              handlShareProfile();
            }}>
            Share Profile
          </Button>
          {copied ? <span className="copied-text">Url Copied!</span> : null}
        </div>
      </div>
      <Modal isOpen={isQRModalOpen} isClosable sm setIsOpen={handleQrModalClick}>
        <div className="qr-modal-content">
          <h2 className="heading">Profile QR</h2>
          <div className="qr-content">
            {qrValue && <QRCode value={qrValue} level="L" />}
            <span className="detail">scan this to view profile</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ProfileCover;
