import React, { useState, useEffect, useContext } from 'react';
import './Avatar.scss';
import UserContext from '../../context/userContext';
import { isUser, getCookie } from '../../helpers/common';

function AvatarUploader({ profile_photo, onChange = () => {}, isEditMode = false }) {
  const [avatarUrl, setAvatarUrl] = useState('');
  const { hasPermission } = useContext(UserContext);
  useEffect(() => {
    if (profile_photo) {
      setAvatarUrl(profile_photo);
    } else {
      setAvatarUrl('');
    }
  }, []);
  const handleAvatarChange = e => {
    const file = e.target.files[0];
    if (!file) {
      setAvatarUrl('');
      return;
    }
    const reader = new FileReader();
    onChange({ name: 'profile_picture', value: file });
    reader.onload = () => {
      setAvatarUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
console.log(`EDITMODE :  ${isEditMode}`);
  return (
    <div className="avatar-uploader">
      {isEditMode ? (
        <>
          <label htmlFor="avatar-input">
            {avatarUrl ? (
              <img className="avatar" src={avatarUrl} alt="Avatar" />
            ) : (
              <div className="avatar-placeholder">Upload Avatar</div>
            )}
          </label>
          <input
            id="avatar-input"
            disabled={!isUser()}
            type="file"
            accept="image/*"
            onChange={e => {
              hasPermission(() => handleAvatarChange(e));
              handleAvatarChange(e);
            }}
          />
        </>
      ) : avatarUrl ? (
        <img className="avatar" src={avatarUrl} alt="Avatar" />
      ) : (
        <div className="avatar-placeholder-readonly"></div>
      )}

    </div>
  );
}

export default AvatarUploader;
