import React, { useContext, useEffect, useState } from 'react';
import { navigate } from '@gatsbyjs/reach-router';
import UserContext from '../../context/userContext';
import userService from '../../services/userService';
import { getCookie } from '../../helpers/common';

function PrivateRoute({ component: Component, location, hideLayout, ...rest }) {
  const { user, setUser } = useContext(UserContext); // Access user and setter from context
  const [loading, setLoading] = useState(true);

  // List of public routes that don't require authentication
  const publicRoutes = [
    '/user/login',
    '/user/verify-otp',
    '/user/link-profile-qr',
    '/user/choose-profile',
    '/user/create-profile',
    '/user/profile',
  ];

  useEffect(() => {
    const authenticateUser = async () => {
      const token = getCookie('itombs_token');
      const sessionID = getCookie('itombs_sessionID');

      if (!token || !sessionID) {
        // If no token/sessionID and accessing a private route, redirect to login
        if (!publicRoutes.includes(location.pathname)) {
          navigate('/user/login');
        }
        setLoading(false);
        return;
      }

      if (!user) {
        try {
          // Fetch the current user's profile using the token
          const profileId = getCookie('current_profile');
          const profile = await userService.getCurrentProfile(profileId);
          console.log(`profile found!`);
          console.log(profile);
          setUser(profile); // Set the user in context
        } catch (error) {
          console.error('Failed to fetch user profile:', error);
          navigate('/user/login'); // Redirect to login on error
        }
      }
      setLoading(false);
    };

    authenticateUser();
  }, [location.pathname, user, setUser]);

  if (loading) {
    // Show a loading spinner or nothing while authenticating
    return null;
  }

  // Render the requested component if authenticated
  return <Component hideLayout={hideLayout} {...rest} />;
}

export default PrivateRoute;
