import React from 'react';
import Field from '../Field';
import './EditProfileForm.scss';
import Button from '../Button';

function EditProfileForm({
  create,
  values,
  setValues,
  errors,
  setErrors,
  btnLoading,
  onSubmit = () => {},
  ...rest
}) {
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  const validateField = (name, value) => {
    let error = '';
    if (name === 'name' && !value) error = 'Name is required';
    return error;
  };

  const handleChange = ({ name, value }) => {
    setValues(prev => ({
      ...prev,
      [name]: value, // Store the raw value for the date field
    }));
    const validationError = validateField(name, value);
    setErrors(prev => ({ ...prev, [name]: validationError }));
  };

  return (
    <div className="edit-form">
      <form onSubmit={handleSubmit}>
        <Field
          type="text"
          variant="form"
          name="name"
          value={values.name}
          label=""
          error={errors.name}
          placeholder="Name"
          onChange={handleChange}
        />
        <Field
          type="date"
          variant="form"
          value={values.date}
          error={errors.date}
          name="date"
          label=""
          placeholder="Date of Birth"
          onChange={handleChange}
        />
        <Field
          type="number"
          variant="form"
          value={values.phone_number}
          name="phone_number"
          label=""
          error={errors.phone_number}
          placeholder="Phone Number"
          onChange={handleChange}
        />
        <Field
          type="text"
          variant="form"
          value={values.address}
          name="address"
          label=""
          error={errors.address}
          placeholder="Address"
          onChange={handleChange}
        />
        <Field
          type="textarea"
          variant="form"
          value={values.about_me}
          name="about_me"
          rows="4"
          label=""
          error={errors.about_me}
          placeholder="About me..."
          onChange={handleChange}
        />
        <div className="btn-holder">
          <Button htmlType="submit" type="submit" variant="primary" loading={btnLoading}>
            {create ? 'Save' : 'Update'}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditProfileForm;
