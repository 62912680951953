/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { getCookie, _urls as backendUrls } from '../helpers/common';
import UserContext from '../context/userContext';

const { BACK_END } = process.env;

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

console.log({ BACK_END });

const userService = {
  _url: BACK_END && backendUrls[BACK_END] ? backendUrls[BACK_END] : backendUrls.dev,

  GetCategories() {
    const [categories, setCategories] = useState([]);
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      const fn = async () => {
        try {
          const res = await this.getCategories();
          setCategories(res?.data);
          setStatus(STATUS.SUCCESS);
        } catch (err) {
          setCategories([]);
          setStatus(STATUS.ERROR);
        }
      };
      fn();
    }, []);
    return {
      categories_loading: status === STATUS.LOADING,
      categories_error: status === STATUS.ERROR ? status : '',
      categories_data: categories,
    };
  },
  GetUserProfiles() {
    const [profiles, setProfiles] = useState([]);
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      const fn = async () => {
        try {
          const res = await this.getUserProfiles();
          setProfiles(res?.data);
          setStatus(STATUS.SUCCESS);
        } catch (err) {
          setProfiles([]);
          setStatus(STATUS.ERROR);
        }
      };
      fn();
    }, []);
    return {
      profiles_loading: status === STATUS.LOADING,
      profiles_error: status === STATUS.ERROR ? status : '',
      profiles_data: profiles,
    };
  },
  GetComments(postId) {
    const [comments, setComments] = useState([]);
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      const fn = async () => {
        try {
          const res = await this.getComments(postId);
          setComments(res?.comments);
          setStatus(STATUS.SUCCESS);
        } catch (err) {
          setComments([]);
          setStatus(STATUS.ERROR);
        }
      };
      fn();
    }, [postId]);
    return {
      comments_loading: status === STATUS.LOADING,
      comments_error: status === STATUS.ERROR ? status : '',
      comments_data: comments,
    };
  },
  GetSuggestedFollowers(searchQuery) {
    const [followers, setFollowers] = useState({});
    const [status, setStatus] = useState(STATUS.LOADING);
    const { profileId, page, itemsPerPage } = searchQuery;
    useEffect(() => {
      const fn = async () => {
        try {
          const res = await this.getSuggestedFollowers({ profileId, page, itemsPerPage });
          setFollowers(res);
          setStatus(STATUS.SUCCESS);
        } catch (err) {
          setFollowers({});
          setStatus(STATUS.ERROR);
        }
      };
      fn();
    }, [profileId, page, itemsPerPage]);
    return {
      followers_loading: status === STATUS.LOADING,
      followers_error: status === STATUS.ERROR ? status : '',
      followers_data: followers,
    };
  },
  GetFollowersByProfile(searchQuery) {
    const [followers, setFollowers] = useState({});
    const [status, setStatus] = useState(STATUS.LOADING);
    const { setFollowedProfiles } = useContext(UserContext);

    const { profileId, page, itemsPerPage } = searchQuery;
    useEffect(() => {
      const fn = async () => {
        try {
          const res = await this.getFollowersByProfile({ profileId, page, itemsPerPage });
          setFollowers(res);
          setFollowedProfiles(res?.items);
          setStatus(STATUS.SUCCESS);
        } catch (err) {
          setFollowers({});
          setStatus(STATUS.ERROR);
        }
      };
      fn();
    }, [profileId, page, itemsPerPage]);
    return {
      followers_loading: status === STATUS.LOADING,
      followers_error: status === STATUS.ERROR ? status : '',
      followers_data: followers,
    };
  },

  // functions
  async login(email) {
    const sessionID = getCookie('itombs_sessionID');
    try {
      localStorage.setItem('itombs_user_email', email);
      const res = await axios.post(`${this._url}/send`, { email, sessionID });
      return res?.data;
    } catch (err) {
      throw new Error(err?.message ?? 'Something went wrong');
    }
  },
  async verifyCode(code) {
    const sessionID = getCookie('itombs_sessionID');
    const email = localStorage.getItem('itombs_user_email');
    try {
      const res = await axios.post(`${this._url}/verify`, { email, sessionID, code });
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async createProfile(payload) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    const email = localStorage.getItem('itombs_user_email');
    try {
      const res = await axios.post(
        `${this._url}/createProfile`,
        { ...payload, token, email, sessionID },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async createPost(payload) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    const email = localStorage.getItem('itombs_user_email');
    try {
      const res = await axios.post(
        `${this._url}/createPost`,
        { ...payload, token, email, sessionID },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async getPosts(id, postId = null, category = null, page = 1, pageSize = 10, only_profile = false, saved = false) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    const email = localStorage.getItem('itombs_user_email');
    try {
      const res = await axios.post(
        `${this._url}/getPosts`,
        { token, email, sessionID, profile_id: id, postId, category, page, pageSize, only_profile, saved },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async getCategories(id) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    const email = localStorage.getItem('itombs_user_email');
    try {
      const res = await axios.get(
        `${this._url}/getCategories`,
        { token, email, sessionID },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },

  async likePost(payload) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    const email = localStorage.getItem('itombs_user_email');
    try {
      const res = await axios.post(
        `${this._url}/toggleLikePost`,
        { ...payload, token, email, sessionID },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async savePost(payload) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    const email = localStorage.getItem('itombs_user_email');
    try {
      const res = await axios.post(
        `${this._url}/savePost`,
        { ...payload, token, email, sessionID },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },

  async linkProfilewithQR(payload) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    // const email = localStorage.getItem('itombs_user_email');
    try {
      const res = await axios.post(
        `${this._url}/linkProfileToQR`,
        { ...payload, url: this._url, token, sessionID },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },

  async getProfileQR(payload) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    try {
      const res = await axios.post(
        `${this._url}/getProfileQR`,
        { ...payload, token, sessionID },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async getUserByQR(payload) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    try {
      const res = await axios.post(
        `${this._url}/getUserByQR`,
        { ...payload, token, sessionID },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async getCurrentProfile(id) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    try {
      const res = await axios.get(
        `${this._url}/getCurrentProfile/${id}`,
        { profileId: id, token, sessionID },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async getUserProfiles(id) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    try {
      const res = await axios.get(`${this._url}/getUserProfiles`, {
        headers: {
          Authorization: token,
        },
      });
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async addComment(payload) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    const email = localStorage.getItem('itombs_user_email');
    try {
      const res = await axios.post(
        `${this._url}/addComment`,
        { ...payload, token, email, sessionID },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async getComments(postId) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    const email = localStorage.getItem('itombs_user_email');
    try {
      const res = await axios.get(`${this._url}/getComments/${postId}`, {
        headers: {
          Authorization: token,
        },
      });
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async getSuggestedFollowers({ profileId, page = 1, itemsPerPage = 10 }) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    try {
      const res = await axios.get(
        `${this._url}/getSuggestedFollowers/${profileId}?page=${page}&itemsPerPage=${itemsPerPage}`,
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async followOrUnfollow({ profile_id, type, following_profile_id, page = 1, itemsPerPage = 10 }) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    try {
      const res = await axios.post(
        `${this._url}/followOrUnfollowUser?page=${page}&itemsPerPage=${itemsPerPage}`,
        { profile_id, type, following_profile_id },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
  async getFollowersByProfile({ profileId, page = 1, itemsPerPage = 10 }) {
    const sessionID = getCookie('itombs_sessionID');
    const token = getCookie('itombs_token');
    try {
      const res = await axios.get(
        `${this._url}/getFollowersByProfile/${profileId}?page=${page}&pageSize=${itemsPerPage}`,
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return res?.data;
    } catch (err) {
      throw Error(err?.message ?? 'Something went wrong');
    }
  },
};
export default userService;
