/* eslint-disable react/jsx-filename-extension */
import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import BannerVideo from '../components/BannerVideo';
import SliderBlock from '../components/SliderBlock';
import TwoColumns from '../components/TwoColumns';
import '../styles/common.scss';
import Button from '../components/Button';
import UserContext from '../context/userContext';
import { getCookie } from '../helpers/common';
import userService from '../services/userService';
// import { navigate } from 'gatsby';

function IndexPage() {
  const { setUser, user } = useContext(UserContext);

  useEffect(() => {
    const profile_id = getCookie('current_profile');
    if (!profile_id) {
      navigate('/user/login');
    } else {
      navigate('/user/feed');
    }
    // if (profile_id && !user) {
    //   console.log('in if ');
    //   userService
    //     .getCurrentProfile(profile_id)
    //     .then(async data => {
    //       console.log({ data });
    //       setUser({ ...data?.data, media: data?.data?.media });
    //       // setLoading(false);
    //     })
    //     .catch(err => {
    //       console.log({ err });
    //     });
    // }
  }, []);

  return (
    <>
    </>
  );
}

export default IndexPage;

export function Head() {
  return <title>Home Page</title>;
}
